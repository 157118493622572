<template>
  <div class="container text-left">
    <h1>Toutes les catégories</h1>
     <template v-for="(c,index) in categories">
        <Category
        :key="c.key"
        :id="c._id"
        :name="c.name"
        :index="index + 1 + ' / '+ categories.length"
        :gradient="c.gradient"/>
      </template>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Category from '../components/Category'
  export default {
    name: 'showCategorys',
    components :{
      Category
    },
    data(){
      return {
        categories : [],
      }
    },
    mounted(){
        this.axios
          .get('https://rse-back.herokuapp.com/api/categorys/')
          .then(response => (this.categories = response.data.data))
          .catch(error => console.log(error));

    }, 

  };
</script>
