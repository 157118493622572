import Vue from 'vue'
import VueRouter from 'vue-router'
import addQuestion from '../views/AddQuestion.vue'
import addCategory from '../views/AddCategory.vue'
import addPartie from '../views/AddPartie.vue'
import showQuestions from '../views/ShowQuestions.vue'
import showCategorys from '../views/ShowCategories.vue'
import showParties from '../views/ShowParties.vue'
import updateQuestion from '../views/UpdateQuestion.vue'
import updateCategory from '../views/UpdateCategory.vue'
import updatePartie from '../views/UpdatePartie.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/addQuestion',
    name: 'addQuestion',
    component: addQuestion
  },
  {
    path: '/addCategory',
    name: 'addCategory',
    component: addCategory
  },
  {
    path: '/addPartie',
    name: 'addPartie',
    component: addPartie
  },
  {
    path: '/questions',
    name: 'ShowQuestions',
    component: showQuestions
  },
  {
    path: '/categorys',
    name: 'ShowCategorys',
    component: showCategorys
  },
  {
    path: '/parties',
    name: 'ShowParties',
    component: showParties
  },
  {
    path: '/questions/:id',
    name: 'UpdateQuestions',
    component: updateQuestion
  },
  {
    path: '/categorys/:id',
    name: 'UpdateCategory',
    component: updateCategory
  },
  {
    path: '/parties/:id',
    name: 'UpdatePartie',
    component: updatePartie
  }
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
