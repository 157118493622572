<template>
	<div  @questionrefresh="getQuestions()" class="container text-left">
		<h1>Toutes les questions</h1>
		<template v-for="(q,index) in questions">
			<Question
				v-if="(catFilter === null || catFilter == q.cat) && (partieFilter === null || partieFilter == q.partie) && (getCategoryById(q.cat) !== undefined) && (getPartieById(q.partie) !== undefined)"
				:key="q.key"
				:question="q.question"
				:id="q._id"
				:categorie="getCategoryById(q.cat).name"
				:partie="getPartieById(q.partie).name"
				:questionType="q.type"
				:options="q.options" 
				:value="q.value"
				:name="q.name"
				:index="index + 1 + ' / '+ questions.length"
				:catSlug="getCategoryById(q.cat).slug"
				:catID="q.cat"
				:gradient="getCategoryById(q.cat).gradient"
				@change="updateScore( $event, q.type ,index ,q.cat)"/>
		</template>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Question from '../components/Question'
	export default {
		name: 'showQuestions',
		components :{
			Question
		},
		data(){
			return {
				catFilter: null,
				partieFilter: null,
				parties : [],
				categories : [],
				questions : []
			}
		},
		mounted(){
			this.axios
			.get('https://rse-back.herokuapp.com/api/categorys')
			.then(response => (this.categories = response.data.data))
			.catch(error => console.log(error));

			this.axios
			.get('https://rse-back.herokuapp.com/api/parties')
			.then(response => (this.parties = response.data.data))
			.catch(error => console.log(error));
			
			this.axios
			.get('https://rse-back.herokuapp.com/api/questions')
			.then(response => (
				this.questions = response.data.data,
				this.questions.sort(function compare(a, b) {
					if (a.partie < b.partie)
						if (a.name < b.name)
							return -1;
					if (a.partie > b.partie )
						if (a.name < b.name)
							return 1;
					return 0;
				}),
				this.initDisable()
			))
			.catch(error => console.log(error));

		}, 

		methods:{
			getCategoryById(id){
				let categoryOBject
				this.categories.forEach((category) =>{
				if (category.id == id) {
					
					categoryOBject = category
				}
				})
				// console.log(categoryOBject)
				// if (categoryOBject == undefined){
				//   let questionsToDelete = this.questions.filter(q => q.cat == id)
				//   console.log(questionsToDelete)
				//   questionsToDelete.map((q) =>{
				//       this.deleteByID(q.id)
				//       this.questions.splice(this.questions.findIndex(question => question.id = q.id), 1)
				//   })
				// }
				return categoryOBject
			},
			getPartieById(id){
				let partieObject
				this.parties.forEach((partie) =>{
					if (partie.id == id) {
						partieObject= partie
					}
				})
				// if (partieObject == undefined){
				//   let questionsToDelete = this.questions.filter(q => q.partie == id)
				//   questionsToDelete.map((q) =>{
				//       this.deleteByID(q.id)
				//       this.questions.splice(this.questions.findIndex(question => question.id = q.id), 1)
				//   })
				// }
				return partieObject
			},
			getQuestions(){
				this.axios
				.get('https://rse-back.herokuapp.com/api/questions')
				.then(response => (
					this.questions = response.data.data,
					this.initDisable()
					))
				.catch(error => console.log(error));
			},
			setCatFilter(catID){
				this.catFilter = catID
				this.partieFilter = null
			},
			setPartieFilter(catID){
				this.partieFilter = catID
				this.catFilter = null
			},
			deleteByID(id){
				this.axios
				.delete('https://rse-back.herokuapp.com/api/questions/'+id)
				.then(response => (
					console.log(response)
					))
				.catch(error => console.log(error));
			},
			filterQuestions(){
				console.log("-------------------------------")
				console.log(this.questions)
			}
		}
	};
</script>
