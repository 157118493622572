<template>
  <div class="container text-left">
    <h1>Modifier une catégorie</h1>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="input-group-2" label="Nom de la catégorie :" label-for="input-2">
            <b-form-input id="input-2" v-model="form.name" placeholder="Entrer le nom" required></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-5" label="Slug de la catégorie :" label-for="input-5">
            <b-form-input id="input-5" v-model="form.slug" placeholder="Entrer le slug" required></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Minimum pour labélisation :" label-for="input-3">
            <b-form-input type="number" id="input-3" v-model="form.min" placeholder="Entrer la valeur minimum à obtenir" required></b-form-input>
        </b-form-group>
        <div class="gradient">
            <div class=" gradientPreview mb-3" :style="{background: form.gradient}">
              <b-icon @click="generateGradient" class="bg-light shuffle float-right rounded p-1 mr-1 mt-1" font-scale="1.5" icon="shuffle"></b-icon>
            </div>
            <div class="row" >
              <b-form-group v-for="(color, index) in colors" :label="'Couleur ' + parseInt(index + 1)"  :key="color.id" class="col-lg-6">
                <b-form-input type="text"  maxlength="7" v-model.trim="color.hex" :style="{color: color.hex}" placeholder="Entrer la valeur minimum à obtenir" required></b-form-input>
              </b-form-group>
          </div>
        </div>
      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
    <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'updateCategory',
    data() {
      return {
        colors: [
          { id: 0, hex: "#f12711", disabled: false },
          { id: 1, hex: "#f5af19", disabled: false }
        ],
        id: 2,
         form: {
          name: '',
          slug: '',
          id: null,
          min: null,
          gradient: null
        },
        show: true,
      }
    },
    mounted(){
      this.axios
          .get('https://rse-back.herokuapp.com/api/categorys/' + this.$route.params.id)
          .then(response => (
            this.form = response.data.data,
            this.colors[0].hex = response.data.data.gradient.substr(22,7),
            this.colors[1].hex = response.data.data.gradient.substr(30,7)
          ))
          .catch(error => console.log(error));
    },
   computed : {
       gradient() {
      let colors = "linear-gradient(45deg";
      this.colors.forEach(function(e) {
        colors += "," + e.hex;
      });
      colors += ")";
      this.form.gradient = colors
      return colors;
    }
    },
     watch: {
      gradient(){
        this.form.gradient = this.gradient
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.axios({
                method: 'put',
                url: 'https://rse-back.herokuapp.com/api/categorys/'+this.form._id,
                data: this.form
            })
            .then(response => (console.log(response)))
            .catch(error => console.log(error))
        console.log('sent')
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form = {
          name: '',
          slug: '',
          id: null,
          min: null,
          gradient: null
        }
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      addColor() {
      this.colors.push({ id: this.id, hex: this.randomHex(), disabled: false });
      this.id++;
    },
    removeColor() {
      if (this.colors[this.colors.length - 1].disabled == false) {
        if (this.colors.length > 2) {
          this.colors.pop();
        }
      }
    },
    generateGradient() {
      for (let i = 0; i < this.colors.length; i++) {
        if (this.colors[i].disabled === false)
          this.colors[i].hex = this.randomHex();
      }
    },
    lockColor(index) {
      this.colors[index].disabled === true
        ? (this.colors[index].disabled = false)
        : (this.colors[index].disabled = true);
    },
    randomHex() {
      return (
        "#" +
        Math.random()
          .toString(16)
          .slice(2, 8)
      );
    },
    up(index) {
      if (index > 0) {
        let temp = this.colors[index];
        this.$set(this.colors, index, this.colors[index - 1]);
        this.$set(this.colors, index - 1, temp);
      }
    },
    down(index) {
      if (index < this.colors.length - 1) {
        let temp = this.colors[index];
        this.$set(this.colors, index, this.colors[index + 1]);
        this.$set(this.colors, index + 1, temp);
      }
    }
    }
  }
</script>
<style scoped>
.gradientPreview {
  height: 50px;
  border-radius: 5px;
  width: 100%;
  transition: .3s;
}
.shuffle{
  cursor: pointer;
  transition: .5s;
}
.shuffle{
  transform: scale(1.1);
}


</style>