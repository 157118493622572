<template>
  <div class="container text-left">
    <h1>Toutes les parties prenantes</h1>
     <template v-for="(c,index) in parties">
        <Partie
        :key="c.key"
        :id="c._id"
        :name="c.name"
        :index="index + 1 + ' / '+ parties.length"/>
      </template>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Partie from '../components/Partie'
  export default {
    name: 'showParties',
    components :{
      Partie
    },
    data(){
      return {
        parties : [],
      }
    },
    mounted(){
        this.axios
          .get('https://rse-back.herokuapp.com/api/parties/')
          .then(response => (this.parties = response.data.data))
          .catch(error => console.log(error));

    }, 

  };
</script>
