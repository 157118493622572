<template>
    <transition>
        <b-card class="question mb-4 p-0">
            <b-card-header>
                {{index}}
            </b-card-header>
            <b-card-body>
            <b-card-title>{{name}}</b-card-title>
            <b-card-footer style="border:none; background : transparent; padding: 0 0 15px;">
                <router-link :to="'/parties/'+ id"> <b-icon class="bg-primary shuffle float-right rounded p-1 ml-2 text-white" font-scale="1.5" icon="pencil"></b-icon></router-link>
                <!-- <b-icon :to="'/questions/'+ id" class="bg-primary shuffle float-right rounded p-1 ml-2 text-white" font-scale="1.5" icon="pencil"></b-icon> -->
                <b-icon @click="deleteByID(id)" class="bg-danger shuffle float-right rounded p-1 text-white" font-scale="1.5" icon="trash"></b-icon>
            </b-card-footer>
            </b-card-body>
        </b-card>
    </transition>
</template>

<script>
export default {
    name:"Partie",
    props:{
        id:{
            type: String,
            required: true
        },
        name:{
            type:String,
            required:true
        },
        index:{
            type:String,
            required: true
        },

    },
    methods :{
        deleteByID(id){
          this.axios
          .delete('https://rse-back.herokuapp.com/api/parties/'+id)
          .then(response => {
              console.log(response);
              document.location.reload()
              })
          .catch(error => console.log(error));
        },

    }
}
</script>

<style lang="css" scope>
    .card-header{
        border: none !important;
    }
    .question.card>.card-body{
        padding: 0  !important;
    }
</style>
