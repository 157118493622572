<template>
  <div class="container text-left">
    <h1>Ajouter une question</h1>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group id="input-group-1" label="Name" label-for="input-1">
        <b-form-input id="input-1" v-model="form.name" type="text"
          placeholder="Entrer le name de la question (ex : question1)" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Question :" label-for="input-2">
        <b-form-input id="input-2" v-model="form.question" placeholder="Entrer la question" required></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-3" label="Catégorie :" label-for="input-5">
        <b-form-select id="input-5" v-model="form.cat" :options="categoryOptions" required></b-form-select>
      </b-form-group>
      <b-form-group id="input-group-3" label="Partie :" label-for="input-5">
        <b-form-select id="input-3" v-model="form.partie" :options="partieOptions" required></b-form-select>
      </b-form-group>
      <b-form-group label="Type de question" id="input-group-4" v-slot="{ ariaDescribedby }">
        <b-form-radio-group v-model="form.type" id="checkboxes-4" :aria-describedby="ariaDescribedby">
          <b-form-radio value="radio">Choix unique</b-form-radio>
          <b-form-radio value="checkbox">Choix multiple</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-button @click="this.addNewAnswer" variant="info" class="mb-3 mr-3">Ajouter une réponse</b-button>
      <b-button @click="this.removeLastAnswer" variant="danger" class="mb-3">Supprimer la dernière réponse</b-button>
      <b-card v-for="(reponse, index) in form.options" :key="index" class="mb-2">
        <b-form-group :label="'Réponse ' +  parseInt(index + 1)" :label-for="'answerName' +  parseInt(index + 1)">
          <b-form-input :id="'answerName' + parseInt(index) + 1" v-model="reponse.label" type="text"
            placeholder="Entrer le name de la réponse (ex : question1)" required></b-form-input>

        </b-form-group>
        <b-form-group :label="'Valeur de la réponse ' + parseInt(index + 1)"
          :label-for="'answerValue' + parseInt(index + 1)">
          <b-form-input :id="'answerValue' + parseInt(index + 1)" v-model="reponse.value" type="number"
            placeholder="Entrer le nombre de point de cette réponse" required></b-form-input>

        </b-form-group>
      </b-card>

      <b-form-group label="Coefficient de la question" label-for="coef">
        <b-form-input id="coef" v-model="form.coef" type="number" placeholder="Entrer le coefficient de la question"
          required></b-form-input>

      </b-form-group>
      <b-form-group label="Minima" label-for="minima">
        <b-form-input id="minima" v-model="form.minima" type="number"
          placeholder="Entrer la valeur minimum attendue de la question" required></b-form-input>

      </b-form-group>
      
      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
    <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'addQuestion',
    data() {
      return {
        form: {
          name: '',
          question: '',
          cat: null,
          partie: null,
          type: null,
          options: [{
            label: null,
            value: null
          }],
          coef: null,
          minima: null
        },
        parties : [],
        categories : [],
        show: true
      }
    },
    mounted(){
      this.axios
          .get('https://rse-back.herokuapp.com/api/categorys')
          .then(response => (this.categories = response.data.data))
          .catch(error => console.log(error));
        this.axios
          .get('https://rse-back.herokuapp.com/api/parties')
          .then(response => (this.parties = response.data.data))
          .catch(error => console.log(error))
    },
    computed:{
        categoryOptions(){
          const options = this.categories.map((c) => {
            return {
              text : c.name,
              value : c.id
            }
          })
          options.unshift({text:"Choisissez une catégorie",value:null});
          return options;
        },
        partieOptions(){
          const options = this.parties.map((p) => {
            return {
              text : p.name,
              value : p.id
            }
          })
          options.unshift({text:"Choisissez une catégorie",value:null});
          return options;
        }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.axios({
                method: 'post',
                url: 'https://rse-back.herokuapp.com/api/questions',
                data: this.form
            })
            .then(response => (console.log(response)))
            .catch(error => console.log(error))
        console.log('sent')
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form = {
           name: '',
          question: '',
          cat: null,
          partie: null,
          type: null,
          options: [{
            label: null,
            value: null
          }],
          coef: null,
          minima: null
        }
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      addNewAnswer() {
        this.form.options.push({
          label: null,
          value: null
        })
      },
      removeLastAnswer() {
        this.form.options.pop()
      }
    }
  }
</script>