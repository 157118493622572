<template>
  <div class="container text-left">
    <h1>Ajouter une partie prenante</h1>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="input-group-2" label="Nom de la partie :" label-for="input-2">
            <b-form-input id="input-2" v-model="form.name" placeholder="Entrer le nom" required></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Slug de la catégorie :" label-for="input-3">
            <b-form-input id="input-3" v-model="form.slug" placeholder="Entrer le slug" required></b-form-input>
        </b-form-group>
      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
    <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'addPartie',
    data() {
      return {
        form: {
          name: '',
          slug: '',
          id: null,
        },
        parties : [],
        categories : [],
        show: true
      }
    },
    
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.form.id = Date.now()
        this.axios({
                method: 'post',
                url: 'https://rse-back.herokuapp.com/api/parties',
                data: this.form
            })
        console.log('sent')
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form = {
          name: '',
          slug: '',
          min: null,
        }
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
  }
</script>